import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useAuth } from "src/utils/AuthContext";

export default function usePermissions() {
  const permissions = useAuth();

  let location = useLocation();
  const currentPage = location.pathname.split("/").pop();

  return _.has(permissions.getPermission, currentPage)
    ? permissions.getPermission[currentPage]
    : false;
}
