import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { appsApi } from "./api";

export default function useDashboardApi({ authToken, userData }) {
  const [response, setResponse] = useState({});

  useEffect(() => {
    if (authToken && userData.role === "admin") {
      getDashboardData();
    }
  }, [authToken]);

  const getDashboardData = async () => {
    try {
      const res = await appsApi.get("dashboard");
      setResponse(res);
    } catch (error) {
      console.error(error);
    }
  };
  return { response };
}
