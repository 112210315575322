import agentContactsController from "./agentContactsController";
import agentController from "./agentController";
import auditController from "./auditController";
import dataReportsController from "./dataReportsController";
import helpTicketController from "./helpTicketController";
import locationController from "./locationController";
import receiptsController from "./receiptsController";
import typesController from "./typesController";
import usersController from "./usersController";

export const types = {
  USERS: "Users",
  AGENTS: "Agents",
  HELPTICKET: "HelpTicket",
  AGENTCONTACTS: "AgentContacts",
  DATAREPORTS: "DataReport",
  RECEIPTS: "Receipts",
  AUDIT: "Audit",
  TYPES: "Types",
  LOCATION: "Location",
};

export const controller = {
  [types.USERS]: usersController,
  [types.AGENTS]: agentController,
  [types.HELPTICKET]: helpTicketController,
  [types.AGENTCONTACTS]: agentContactsController,
  [types.DATAREPORTS]: dataReportsController,
  [types.RECEIPTS]: receiptsController,
  [types.AUDIT]: auditController,
  [types.TYPES]: typesController,
  [types.LOCATION]: locationController,
};
