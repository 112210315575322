import {
  SET_USER_DATA,
  SET_AUTH_TOKEN,
  LOGOUT,
  SET_AGENT,
  SET_TYPES,
} from "./types";

const actions = {
  setUserData: (userData) => (dispatch) =>
    dispatch({
      type: SET_USER_DATA,
      userData,
    }),

  setAuthToken: (authToken) => (dispatch) =>
    dispatch({
      type: SET_AUTH_TOKEN,
      authToken,
    }),

  logout: () => (dispatch) =>
    dispatch({
      type: LOGOUT,
    }),

  setAgentDetails: (agentDetails) => (dispatch) =>
    dispatch({
      type: SET_AGENT,
      agentDetails,
    }),
  setTypes: (types) => (dispatch) =>
    dispatch({
      type: SET_TYPES,
      types,
    }),
};

export default actions;
