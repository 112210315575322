import { Grid, Container, Typography, Button } from "@mui/material";
// components
import Page from "../components/Page";
// sections
import { useAuth } from "src/utils/AuthContext";
import { AppWidgetSummary } from "src/sections/@dashboard/app";
import { useNavigate } from "react-router-dom";
import Iconify from "src/components/Iconify";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { apis } = useAuth();
  const { response } = apis.dashboardApi;
  const nav = useNavigate();
  const data = [
    {
      title: "Total Agents",
      value: response?.totalAgents || 0,
      icon: "fa-solid:user-tie",
      link: "/admin/agents",
    },
    {
      title: " Total Users",
      value: response?.totalUser || 0,
      icon: "heroicons:users-solid",
      link: "/admin/users",
    },
    {
      title: "Help Tickets",
      value: response?.totalHelpTickets || 0,
      icon: "ph:headset-fill",
      link: "/admin/help",
    },
    {
      title: "Receipt Reports",
      value: response?.totalReceipts || 0,
      icon: "fluent:receipt-32-filled",
      link: "/admin/receipt",
    },
    {
      title: "Agent Contacts",
      value: response?.totalContact || 0,
      icon: "fluent:contact-card-32-filled",
      link: "/admin/contacts",
    },
    {
      title: "Audit Reports",
      value: response?.totalAudit || 0,
      icon: "material-symbols:fact-check-rounded",
      link: "/admin/audit",
    },
    {
      title: "Agent Locations",
      value: response?.totalLocation || 0,
      icon: "mingcute:location-2-fill",
      link: "/admin/location",
    },
    {
      title: "User Data",
      value: response?.totalData || 0,
      icon: "fluent:document-landscape-data-20-filled",
      link: "/admin/data",
    },
    {
      title: "Agent Types",
      value: response?.totalType || 0,
      icon: "ion:id-card",
      link: "/admin/type",
    },
  ];
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>
          Dashboard
        </Typography>
        <Button
          variant="contained"
          startIcon={<Iconify icon="bx:download" />}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 3,
            color: "white",
            fontSize: "16px",
            "&:hover": {
              bgcolor: "#4A148C",
            },
          }}
          onClick={() => {
            window.open(
              "https://agent-relation-backend-a46b57b96b9f.herokuapp.com/v1/users/download-admin-csv-data",
              "_self",
            );
          }}
        >
          Download Agents CSV
        </Button>
        <Grid container spacing={3}>
          {data.map((d) => {
            return (
              <Grid key={d.title} item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  onClick={() => nav(d.link, { replace: true })}
                  title={d.title}
                  total={d.value}
                  color="primary"
                  icon={d.icon}
                  sx={{
                    py: 5,
                    boxShadow: 3,
                    textAlign: "center",
                    "&:hover": {
                      cursor: "pointer",
                    },
                    color: (theme) => theme.palette.primary.dark,
                    bgcolor: (theme) => theme.palette.common.white,
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Page>
  );
}
