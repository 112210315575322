import { notiRef } from "src/hooks/useNotify";
import Axios from "axios";
import _ from "lodash";
import { API_BASE_URL } from "src/utils/config";
import { store } from "src/redux/store/store";
import actions from "src/redux/actions/actions";

export const appsApi = Axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  validateStatus: (status) => [1, 200, 201].includes(status),
});

appsApi.interceptors.request.use((config) => {
  const token = store ? store.getState().reducer.authToken : "";
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

appsApi.interceptors.response.use(responseHandler, errorHandler);

function responseHandler(response) {
  if (!_.isEmpty(response?.data)) {
    return response.data;
  }
  if (response?.status == 200) {
    return response.data;
  }

  return Promise.reject(response);
}

function errorHandler({ response }) {
  // console.log(response);

  if (response?.data?.code === 401) {
    store.dispatch(actions.logout());
    store.dispatch(actions.setAgentDetails(false));
    if (response?.data?.message) {
      notiRef?.current?.toastError(response?.data?.message);
      return;
    }
  }
  if (response?.message) {
    notiRef?.current?.toastError(response?.message);
    return;
  }
  if (response?.data?.message) {
    notiRef?.current?.toastError(response?.data?.message);
    return;
  }
  notiRef?.current?.toastError("Something went wrong");
}
