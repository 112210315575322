import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { appsApi } from "src/api/api";
import _ from "lodash";
import {
  Box,
  Stack,
  ListItemButton,
  ListItemText,
  Card,
  Typography,
  InputAdornment,
} from "@mui/material";
import CommonSpinner from "src/components/CommonSpinner";
import { alpha, useTheme, styled } from "@mui/material/styles";
import { getIcon } from "src/layouts/dashboard/NavConfig";
import { useDispatch } from "react-redux";
import actions from "src/redux/actions/actions";

const minInputLength = 2;
const RHFSearchBox = ({
  suggestions,
  type,
  setSuggestions,
  agentDetails,
  ...other
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState("");

  const ListItemStyle = styled((props) => (
    <ListItemButton disableGutters {...props} />
  ))(({ theme }) => ({
    ...theme.typography.body2,
    position: "relative",
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    fontSize: "18px",
    borderRadius: theme.shape.borderRadius,
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
  }));

  const handleInputChange = async (event) => {
    setInputValue(event.target.value);
    if (event.target.value.length <= 1) {
      setSuggestions([]);
    }
    if (event.target.value.length >= 2) {
      try {
        const data = await appsApi.get(
          `users/agent?agentNumber=${inputValue}&forAgentSearch=true`,
        );
        if (_.isArray(data)) {
          setSuggestions(data);
        } else {
          setSuggestions([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    }
  };

  async function handleSuggestionsClick(selectedAgent) {
    setSuggestions([]);
    dispatch(actions.setAgentDetails(selectedAgent));

    setInputValue("");
  }
  return (
    <>
      <Card sx={{ p: 2, maxWidth: "800px", mx: "auto" }}>
        <TextField
          type="text"
          fullWidth
          value={inputValue}
          onChange={handleInputChange}
          {...other}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ m: 1 }}>
                {getIcon("mingcute:search-3-line")}
              </InputAdornment>
            ),
          }}
        />
        <Box
          style={{
            maxHeight: "500px",
            overflowY: "scroll",
          }}
        >
          {inputValue.length > 0 &&
            inputValue.length <= minInputLength &&
            suggestions &&
            suggestions.length == 0 && (
              <Box sx={{ mt: 2 }}>
                <CommonSpinner />
              </Box>
            )}
          {suggestions.length > 0 &&
            (!agentDetails || _.isObject(agentDetails)) &&
            suggestions.map((suggestion) => (
              <Box
                onClick={() => handleSuggestionsClick(suggestion)}
                key={suggestion.id}
                sx={{
                  ".css-1tpn7c0-MuiButtonBase-root-MuiListItemButton-root:hover":
                    {
                      backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                      ),
                    },
                  mt: 2,
                }}
              >
                <ListItemStyle>
                  <Stack
                    flex
                    flexDirection={"row"}
                    alignItems={"center"}
                    sx={{ width: "80%", p: 1 }}
                  >
                    <ListItemText
                      sx={{ textAlign: "left" }}
                      disableTypography
                      primary={suggestion.agentNumber}
                    />
                    <ListItemText
                      sx={{ textAlign: "left" }}
                      disableTypography
                      primary={suggestion.name}
                    />
                  </Stack>
                </ListItemStyle>
              </Box>
            ))}
          {inputValue.length > minInputLength &&
            suggestions &&
            suggestions.length == 0 && (
              <Typography
                variant="subtitle1"
                sx={{ textAlign: "center", mt: 2 }}
              >
                No Agents Found
              </Typography>
            )}
        </Box>
      </Card>
    </>
  );
};

RHFSearchBox.propTypes = {
  label: PropTypes.string,
  suggestions: PropTypes.array.isRequired,
  setSuggestions: PropTypes.func.isRequired,
};

export default RHFSearchBox;
