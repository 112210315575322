import { Grid, TableCell } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RHFSelect, RHFTextField } from "src/components/hook-form";
import { store } from "src/redux/store/store";
import { getFormattedDate, isIsoDate } from "src/utils/commons";
import * as Yup from "yup";

const sectionTitle = "Agents";
//API
const apiHook = "agentsApi";
const endPoints = {
  get: "users/agent",
  create: "users/agent",
  update: "users/",
  remove: "users/",
};
//TABLE
const enablePagination = true;
const tableHead = [
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "agentLocation", label: "Location", alignRight: false },
  { id: "agentType", label: "Type", alignRight: false },
  { id: "agentNumber", label: "Agent Number", alignRight: false },
  { id: "agentDevice", label: "Device", alignRight: false },
  { id: "realPassword", label: "Password", alignRight: false },
  { id: "agentSim", label: "SIM Number", alignRight: false },
  { id: null, label: "Actions", alignRight: true },
];
const tableActions = { edit: true, delete: true, create: false };
const tableRowRender = (rowData) =>
  tableHead.map((head) => {
    if (!_.has(rowData, head.id)) return;
    if (head.id === "agentType") {
      return (
        <TableCell key={head.id} align="left">
          {rowData[head.id].name}
        </TableCell>
      );
    }
    if (isIsoDate(rowData[head.id])) {
      return (
        <TableCell key={head.id} align="left">
          {getFormattedDate(rowData[head.id])}
        </TableCell>
      );
    }
    return (
      <TableCell key={head.id} align="left">
        {rowData[head.id]}
      </TableCell>
    );
  });

//FORMS
const formFields = [
  {
    component: RHFTextField,
    formProps: {
      name: "name",
      label: "Name",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "email",
      label: "Email",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "password",
      label: "Password",
      type: "password",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "role",
      label: "Role",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "agentNumber",
      label: "Agent Number",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "agentLocation",
      label: "Agent Location",
    },
  },
  {
    component: RHFSelect,
    formProps: {
      name: "agentType",
      label: "Agent Type",
      type: "agentTypes",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "agentDevice",
      label: "Agent Device",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "deviceSerial",
      label: "Device Serial",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "agentSim",
      label: "SIM Number",
    },
  },
];

const formLayout = (
  <Grid container spacing={3}>
    <Grid item xs={6}>
      <RHFTextField name={"name"} label={"Name"} />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField name={"email"} label={"Email"} />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField name={"password"} label={"Password"} type="password" />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField name={"agentNumber"} label={"Agent Number"} />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField name={"agentLocation"} label={"Agent Location"} />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField name={"deviceSerial"} label={"Device Serial"} />
    </Grid>
    <Grid item xs={4}>
      <RHFTextField name={"agentDevice"} label={"Agent Device"} />
    </Grid>
    <Grid item xs={4}>
      <RHFSelect name={"agentType"} label={"Agent Type"} type="agentTypes" />
    </Grid>
    <Grid item xs={4}>
      <RHFTextField name={"agentSim"} label={"SIM Number"} />
    </Grid>
  </Grid>
); //Auto render layout if false or pass react element with form render

const formSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email().required("Email is required"),
  agentNumber: Yup.string().required("Agent Number is required"),
  agentLocation: Yup.string().required("Agent Location is required"),
  agentType: Yup.string().required("Agent Type is required"),
  agentSim: Yup.string().required("SIM Number is required"),
  deviceSerial: Yup.string().required("Device Serial is required"),
  agentDevice: Yup.string().required("Agent Device is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).*$/,
      "Password must contain at least 1 letter and 1 number",
    )
    .required("Password is required"),
  role: Yup.string(),
});
const formDefaultValues = {
  agentType: "None",
};

const formTitle = { create: "Create Agent", edit: "Edit Agent" };

//Read only form fields when not edit/delete available
const readOnlyFieldsMap = {};

export default {
  apiHook,
  formFields,
  formLayout,
  sectionTitle,
  tableHead,
  tableActions,
  formSchema,
  formTitle,
  formDefaultValues,
  endPoints,
  enablePagination,
  tableRowRender,
  readOnlyFieldsMap,
};
