import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom/dist";

export default function GoToAgentDetails({ textColor, sx, visible, route }) {
  const navigate = useNavigate();
  function handleRoutes() {
    navigate(route, { replace: true });
  }
  if (!visible) return <></>;
  return (
    <Box position={"absolute"} sx={{ ...sx }}>
      <Button
        variant="text"
        sx={{
          text: "14px",
          color: textColor,
          borderColor: "black",
          "&:hover": {
            bgcolor: "#4A148C",
            color: "white",
            borderColor: "black",
          },
        }}
        onClick={() => {
          handleRoutes();
        }}
      >
        Go To Agent Details
      </Button>
    </Box>
  );
}
