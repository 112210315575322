import useAgentContactsApi from "src/api/useAgentContactsApi";
import useAgentsApi from "src/api/useAgentsApi";
import useAuditApi from "src/api/useAuditApi";
import useDashboardApi from "src/api/useDashboardApi";
import useDataReportsApi from "src/api/useDataReportsApi";
import useHelpTicketsApi from "src/api/useHelpTicketsApi";
import useLocationApi from "src/api/useLocationApi";
import useReceiptsApi from "src/api/useReceiptsApi";
import useTypesApi from "src/api/useTypesApi";
import useUsersApi from "src/api/useUsersApi";
import { types } from "src/controllers";

export default function useApiProvider({
  authToken: authToken,
  userData: userData,
}) {
  const dashboardApi = useDashboardApi({
    authToken: authToken,
    userData: userData,
  });

  const usersApi = useUsersApi({
    authToken: authToken,
    userData: userData,
    type: types.USERS,
  });

  const helpTicketsApi = useHelpTicketsApi({
    authToken: authToken,
    userData: userData,
    type: types.HELPTICKET,
  });
  const agentContactsApi = useAgentContactsApi({
    authToken: authToken,
    userData: userData,
    type: types.AGENTCONTACTS,
  });
  const dataReportsApi = useDataReportsApi({
    authToken: authToken,
    userData: userData,
    type: types.DATAREPORTS,
  });
  const receiptsApi = useReceiptsApi({
    authToken: authToken,
    userData: userData,
    type: types.RECEIPTS,
  });
  const auditApi = useAuditApi({
    authToken: authToken,
    userData: userData,
    type: types.AUDIT,
  });
  const agentsApi = useAgentsApi({
    authToken: authToken,
    userData: userData,
    type: types.AGENTS,
  });
  const typesApi = useTypesApi({
    authToken: authToken,
    userData: userData,
    type: types.TYPES,
  });
  const locationApi = useLocationApi({
    authToken: authToken,
    userData: userData,
    type: types.LOCATION,
  });

  return {
    usersApi,
    agentsApi,
    helpTicketsApi,
    agentContactsApi,
    dataReportsApi,
    dashboardApi,
    receiptsApi,
    locationApi,
    auditApi,
    typesApi,
  };
}
