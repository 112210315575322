import { Grid, TableCell } from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import { RHFDatePicker, RHFTextField } from "src/components/hook-form";
import { getFormattedDate, isIsoDate } from "src/utils/commons";
import * as Yup from "yup";

const sectionTitle = "Audit Report";
//API
const apiHook = "auditApi";
const endPoints = {
  get: "audit",
  create: "audit",
  remove: "audit/",
  update: "audit/",
};
//TABLE
const enablePagination = true;
const tableHead = [
  { id: "number", label: "Audit Number", alignRight: false },
  { id: "agentId", label: "Agent Number", alignRight: false },
  { id: "siteInspectionDate", label: "Inspection Date", alignRight: false },
  {
    id: "siteInspectionComment",
    label: "Inspection Comment",
    alignRight: false,
  },
  {
    id: "appStatusComment",
    label: "App Status Comment",
    alignRight: false,
  },
  {
    id: "deviceStatusComment",
    label: "Device Status Comment",
    alignRight: false,
  },
  {
    id: "signageStatusComment",
    label: "Signage Status Comment",
    alignRight: false,
  },
  { id: null, label: "", alignRight: true },
];
const tableActions = { edit: false, delete: false, create: true };
const tableRowRender = (rowData) =>
  tableHead.map((head) => {
    if (!_.has(rowData, head.id)) return;
    if (head.id === "agentId" && _.isObject(rowData[head.id])) {
      return (
        <TableCell key={head.id} align="left">
          {rowData[head.id].agentNumber}
        </TableCell>
      );
    }
    if (isIsoDate(rowData[head.id])) {
      return (
        <TableCell key={head.id} align="left">
          {getFormattedDate(rowData[head.id])}
        </TableCell>
      );
    }
    return (
      <TableCell key={head.id} align="left">
        {rowData[head.id]}
      </TableCell>
    );
  });

//FORMS
const formFields = [
  {
    component: RHFTextField,
    formProps: {
      name: "agentId",
      label: "Agent Number",
    },
  },
  {
    component: RHFDatePicker,
    formProps: {
      name: "siteInspectionDate",
      label: "Site Inspection Date",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "siteInspectionComment",
      label: "Inspection Comment",
      multiline: true,
      maxRows: 5,
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "appStatusComment",
      label: "App Status Comment",
      multiline: true,
      maxRows: 5,
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "deviceStatusComment",
      label: "Device Status Comment",
      multiline: true,
      maxRows: 5,
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "signageStatusComment",
      label: "Signage Status Comment",
      multiline: true,
      maxRows: 5,
    },
  },
];

const formLayout = (
  <Grid container spacing={3}>
    <Grid item xs={6}>
      <RHFDatePicker name="siteInspectionDate" label={"Site Inspection Date"} />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField
        name="siteInspectionComment"
        label={"Site Inspection Comment"}
        multiline
        maxRows={5}
      />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField
        name="appStatusComment"
        label={"App Status Comment"}
        multiline
        maxRows={5}
      />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField
        name="deviceStatusComment"
        label={"Device Status Comment"}
        multiline
        maxRows={5}
      />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField
        name="signageStatusComment"
        label={"Signage Status Comment"}
        multiline
        maxRows={5}
      />
    </Grid>
  </Grid>
); //Auto render layout if false or pass react element with form render

const formSchema = Yup.object().shape({
  agentId: Yup.string(),
  siteInspectionDate: Yup.string().required("Inspection Date is required"),
  siteInspectionComment: Yup.string().required(
    "Site Inspection Comment is required",
  ),
  appStatusComment: Yup.string().required("App Status Comment is required"),
  deviceStatusComment: Yup.string().required(
    "Device Status Comment is required",
  ),
  signageStatusComment: Yup.string().required(
    "Signage Status Comment is required",
  ),
});
const formDefaultValues = {
  siteInspectionDate: dayjs(),
  siteInspectionComment: "",
  appStatusComment: "",
  deviceStatusComment: "",
  signageStatusComment: "",
};

const formTitle = {
  create: "Create New Audit Record",
  edit: "Edit Audit Record",
};

//Read only form fields when not edit/delete available
const readOnlyFieldsMap = {};

export default {
  apiHook,
  formFields,
  formLayout,
  tableHead,
  tableActions,
  formSchema,
  formTitle,
  formDefaultValues,
  sectionTitle,
  endPoints,
  enablePagination,
  tableRowRender,
  readOnlyFieldsMap,
};
