import Iconify from "../../components/Iconify";

export const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "Dashboard",
    path: "/admin/dashboard",
    icon: getIcon("mdi:monitor-dashboard"),
  },
  {
    title: "Agents",
    path: "/admin/agents",
    icon: getIcon("icon-park-outline:user-business"),
  },
  {
    title: "Users",
    path: "/admin/users",
    icon: getIcon("heroicons:users"),
  },
  {
    title: "Help",
    path: "/admin/help",
    icon: getIcon("iconoir:headset-help"),
  },
  {
    title: "Receipt",
    path: "/admin/receipt",
    icon: getIcon("fluent:receipt-28-regular"),
  },
  {
    title: "Contacts",
    path: "/admin/contacts",
    icon: getIcon("fluent:contact-card-28-regular"),
  },
  {
    title: "Audit",
    path: "/admin/audit",
    icon: getIcon("material-symbols:fact-check-outline"),
  },
  {
    title: "Locations",
    path: "/admin/location",
    icon: getIcon("mingcute:location-2-line"),
  },
  {
    title: "User Data",
    path: "/admin/data",
    icon: getIcon("fluent:document-landscape-data-24-regular"),
  },
  {
    title: "Agent Type",
    path: "/admin/type",
    icon: getIcon("ion:id-card-outline"),
  },
];

export default navConfig;
