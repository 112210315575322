import { Grid, TableCell } from "@mui/material";
import _ from "lodash";
import { RHFSelect, RHFTextField } from "src/components/hook-form";
import { getFormattedDate, isIsoDate } from "src/utils/commons";
import * as Yup from "yup";

const sectionTitle = "Help Tickets";
//API
const apiHook = "helpTicketsApi";
const endPoints = {
  get: "ticket",
  create: "ticket",
  update: "ticket/",
  remove: "ticket/",
};
//TABLE
const enablePagination = true;
const tableHead = [
  { id: "number", label: "TICKET NUMBER", alignRight: false },
  { id: "agentId", label: "Agent Number", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "comment", label: "Comment", alignRight: false },
  { id: null, label: "", alignRight: true },
];
const tableActions = { edit: false, delete: false, create: true };
const tableRowRender = (rowData) =>
  tableHead.map((head) => {
    if (!_.has(rowData, head.id)) return;
    if (head.id === "agentId" && _.isObject(rowData[head.id])) {
      return (
        <TableCell key={head.id} align="left">
          {rowData[head.id].agentNumber}
        </TableCell>
      );
    }
    if (isIsoDate(rowData[head.id])) {
      return (
        <TableCell key={head.id} align="left">
          {getFormattedDate(rowData[head.id])}
        </TableCell>
      );
    }
    return (
      <TableCell key={head.id} align="left">
        {rowData[head.id]}
      </TableCell>
    );
  });

//FORMS
const formFields = [
  {
    component: RHFTextField,
    formProps: {
      name: "agentId",
      label: "Agent ID",
    },
  },
  {
    component: RHFSelect,
    formProps: {
      name: "type",
      label: "Type",
      options: ["Important", "Urgent", "Normal"],
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "status",
      label: "Status",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "comment",
      label: "Comment",
      multiline: true,
      maxRows: 5,
    },
  },
];

const formLayout = (
  <Grid container spacing={3}>
    <Grid item xs={6}>
      <RHFSelect
        name="type"
        label="Type"
        options={["Important", "Urgent", "Normal"]}
      />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField name="status" label={"Status"} />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField name="comment" label={"Comment"} multiline maxRows={5} />
    </Grid>
  </Grid>
); //Auto render layout if false or pass react element with form render

const formSchema = Yup.object().shape({
  agentId: Yup.string(),
  type: Yup.string().required("Agent Type is required"),
  status: Yup.string().required("Status is required"),
  comment: Yup.string().required("Comment is required"),
});
const formDefaultValues = {};

const formTitle = { create: "Create Help Ticket", edit: "Edit Help Ticket" };

//Read only form fields when not edit/delete available
const readOnlyFieldsMap = {};

export default {
  apiHook,
  formFields,
  formLayout,
  tableHead,
  tableActions,
  formSchema,
  formTitle,
  formDefaultValues,
  sectionTitle,
  endPoints,
  enablePagination,
  tableRowRender,
  readOnlyFieldsMap,
};
