import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useMemo } from "react";

RHFSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  disableNoneOption: PropTypes.bool,
};

export default function RHFSelect({
  name,
  label,
  type,
  disableNoneOption = true,
  options,
  ...other
}) {
  const { control } = useFormContext();
  const agentTypesReducer = useSelector((state) => state.reducer.types);
  let optionsMap = [];
  if (type === "agentTypes") {
    optionsMap = agentTypesReducer.map((o) => {
      return {
        label: o.name,
        value: o.id,
      };
    });
  } else {
    optionsMap = options.map((o) => {
      return {
        label: o,
        value: o,
      };
    });
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Stack direction="column">
            <FormControl error={error && error.message}>
              <InputLabel
                id={label}
                sx={{
                  backgroundColor: "white",
                  paddingLeft: 0.5,
                  paddingRight: 0.5,
                }}
              >
                {label}
              </InputLabel>
              <Select labelId={label} {...field} {...other} error={!!error}>
                {!disableNoneOption && (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )}
                {optionsMap.map((op, index) => (
                  <MenuItem key={`${op.label}_${index}`} value={op.value}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{error?.message || ""}</FormHelperText>
            </FormControl>
          </Stack>
        );
      }}
    />
  );
}
