import PropTypes from "prop-types";
// material
import {
  Box,
  Drawer,
  Typography,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
// mock
import Iconify from "src/components/Iconify";
import { FormProvider } from "src/components/hook-form";
import { LoadingButton } from "@mui/lab";
import Scrollbar from "src/components/Scrollbar";
import { useNavigate } from "react-router-dom/dist";
import { useDispatch, useSelector } from "react-redux";
import actions from "src/redux/actions/actions";
import GoToDashboard from "src/components/GoToDashboard";

CommonForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  formTitle: PropTypes.string,
  children: PropTypes.object,
  drawerWidth: PropTypes.number,
};

export default function CommonForm({
  onClose,
  methods,
  onSubmit,
  agentNumber,
  isSubmitting,
  formTitle,
  children,
}) {
  const { handleSubmit } = methods;
  const userData = useSelector((state) => state.reducer.userData);

  return (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mb: 5, mx: 4 }}>
          <Stack direction="row" sx={{ mt: 4, mb: 5 }} alignItems="center">
            <GoToDashboard
              sx={{ bottom: 5, left: 3, px: 2, py: 1, userSelect: "none" }}
            />
            <Typography variant="h5" flex={1}>
              {formTitle}
            </Typography>
            {agentNumber && (
              <Typography
                variant="subtitle1"
                textAlign={"center"}
                display={"flex"}
                sx={{ width: "40%" }}
              >
                Agent Number: {agentNumber}
              </Typography>
            )}
            <IconButton onClick={onClose}>
              <Iconify icon="codicon:chrome-close" width={30} height={30} />
            </IconButton>
          </Stack>
          <Stack spacing={3}>{children}</Stack>
          <Stack
            direction="row"
            sx={{ mt: 4 }}
            justifyContent="end"
            spacing={2}
          >
            <LoadingButton
              size="large"
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </FormProvider>
    </Scrollbar>
  );
}
