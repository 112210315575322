import React, { createContext, useContext } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import GlobalAlert from "src/components/GlobalAlert";
import { useEffectSkipFirst } from "src/hooks/useEffectSkipFirst";
import { notiRef } from "src/hooks/useNotify";
import actions from "src/redux/actions/actions";
import useApiProvider from "./useApiProvider";
import { replace } from "lodash";

const getPermission = (role) => {
  const PERMISSIONS = {
    admin: {
      help: {
        create: false,
        edit: true,
        delete: true,
      },
      agents: {
        create: false,
        edit: true,
        delete: false,
        search: true,
      },
      audit: {
        create: false,
        edit: false,
        delete: false,
      },
      location: {
        create: true,
        edit: true,
        delete: true,
      },
      users: {
        create: true,
        edit: false,
        delete: true,
      },
      type: {
        create: true,
        edit: true,
        delete: true,
      },
      data: {
        create: false,
        edit: false,
        delete: false,
      },
      contacts: {
        create: false,
        edit: false,
        delete: false,
      },
      receipt: {
        create: false,
        edit: false,
        delete: false,
      },
    },
    user: {
      help: {
        create: true,
        edit: false,
        delete: false,
        search: true,
      },
      audit: {
        create: true,
        edit: false,
        delete: false,
        search: true,
      },
      receipt: {
        create: true,
        edit: false,
        delete: false,
        search: true,
      },
      data: {
        create: true,
        edit: false,
        delete: false,
        search: true,
      },
      contacts: {
        create: true,
        edit: false,
        delete: false,
        search: true,
      },
    },
  };

  return PERMISSIONS[role];
};

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const { authToken, userData } = useSelector((state) => state.reducer);
  const navigate = useNavigate();
  useEffectSkipFirst(() => navigate("/", { replace: true }), [authToken]);

  const dispatch = useDispatch();

  const handleLogout = () => {
    if (userData.role === "admin") {
      dispatch(actions.logout());
      setTimeout(() => {
        navigate("/admin", { replace: true });
      }, [2]);
    }
    dispatch(actions.logout());
  };

  const apis = useApiProvider({ authToken: authToken, userData: userData });
  const value = {
    authToken: authToken,
    userData: userData,
    handleLogout: handleLogout,
    apis,
    getPermission: userData?.role ? getPermission(userData?.role) : {},
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AuthContext.Provider value={value}>
        <GlobalAlert ref={notiRef} />
        {children}
      </AuthContext.Provider>
    </LocalizationProvider>
  );
};
