import { Grid, TableCell } from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import { RHFDatePicker, RHFTextField } from "src/components/hook-form";
import { getFormattedDate, isIsoDate } from "src/utils/commons";
import * as Yup from "yup";

const sectionTitle = "Receipts";
//API
const apiHook = "receiptsApi";
const endPoints = {
  get: "receipt",
  create: "receipt",
  update: "receipt/",
  remove: "receipt/",
};
//TABLE

const enablePagination = true;
const tableHead = [
  { id: "dateDelivered", label: "Delivery Date", alignRight: false },
  { id: "numberOfRolls", label: "Number of Rolls", alignRight: false },
  { id: "comment", label: "Comments", alignRight: false },
  { id: "agentId", label: "Agent Number", alignRight: false },

  { id: null, label: "", alignRight: true },
];
const tableActions = { edit: false, delete: false, create: true };
const tableRowRender = (rowData) =>
  tableHead.map((head) => {
    if (!_.has(rowData, head.id)) return;
    if (head.id === "agentId" && _.isObject(rowData[head.id])) {
      return (
        <TableCell key={head.id} align="left">
          {rowData[head.id].agentNumber}
        </TableCell>
      );
    }
    if (isIsoDate(rowData[head.id])) {
      return (
        <TableCell key={head.id} align="left">
          {getFormattedDate(rowData[head.id])}
        </TableCell>
      );
    }
    return (
      <TableCell key={head.id} align="left">
        {rowData[head.id]}
      </TableCell>
    );
  });

//FORMS
const formFields = [
  {
    component: RHFTextField,
    formProps: {
      name: "agentId",
      label: "Agent Number",
    },
  },
  {
    component: RHFDatePicker,
    formProps: {
      name: "dateDelivered",
      label: "Issue Date",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "numberOfRolls",
      label: "Number of Rolls",
    },
  },

  {
    component: RHFTextField,
    formProps: {
      name: "comment",
      label: "Comment",
      multiline: true,
      maxRows: 5,
    },
  },
];

const formLayout = (
  <Grid container spacing={3}>
    <Grid item xs={6}>
      <RHFDatePicker name="dateDelivered" label={"Date roll Issued"} />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField name="numberOfRolls" label={"Number of Rolls"} />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField name="comment" label={"Comment"} multiline maxRows={5} />
    </Grid>
  </Grid>
); //Auto render layout if false or pass react element with form render

const formSchema = Yup.object().shape({
  agentId: Yup.string(),
  dateDelivered: Yup.string().required("Issue Date is required"),
  numberOfRolls: Yup.string().required("Number of rolls is required"),
  comment: Yup.string().required("Comment is required"),
});
const formDefaultValues = {
  dateDelivered: dayjs(),
  numberOfRolls: "",
  comment: "",
};

const formTitle = {
  create: "Create New Receipt Record",
  edit: "Edit Receipt Record",
};

//Read only form fields when not edit/delete available
const readOnlyFieldsMap = {};

export default {
  apiHook,
  formFields,
  formLayout,
  tableHead,
  tableActions,
  formSchema,
  formTitle,
  formDefaultValues,
  sectionTitle,
  endPoints,
  enablePagination,
  tableRowRender,
  readOnlyFieldsMap,
};
