import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import actions from "src/redux/actions/actions";

export default function GoToDashboard({ textColor, sx, visible }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function handleRoutes(route, resetValue) {
    navigate(route);
    resetValue && dispatch(actions.setAgentDetails(false));
  }
  if (!visible) return <></>;
  return (
    <Box position={"absolute"} sx={{ ...sx }}>
      <Button
        variant="text"
        sx={{
          text: "14px",
          color: textColor,
          borderColor: "black",
          "&:hover": {
            bgcolor: "#4A148C",
            color: "white",
            borderColor: "black",
          },
        }}
        onClick={() => {
          handleRoutes("/", true);
        }}
      >
        Go To Dashboard
      </Button>
    </Box>
  );
}
