import {
  Grid,
  Container,
  Typography,
  Stack,
  IconButton,
  InputAdornment,
  Box,
  Button,
  Card,
} from "@mui/material";
// components
import Page from "../components/Page";
// sections
import { useAuth } from "src/utils/AuthContext";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import styled from "@emotion/styled";
import CommonForm from "src/layouts/dashboard/CommonForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getValueFromObject } from "src/utils/commons";
import { useEffect, useMemo, useState } from "react";
import { controller } from "src/controllers";
import * as Yup from "yup";
import Iconify from "src/components/Iconify";
import { appsApi } from "src/api/api";
import { LoadingButton } from "@mui/lab";
import _ from "lodash";
import AgentDetailsCard from "src/components/AgentDetailsCard";
import RHFSearchBox from "src/components/hook-form/RHFSearchbox";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

export default function SearchAgents({ type }) {
  const { apis } = useAuth();
  const agentDetails = useSelector((state) => state.reducer.agentDetails);
  const [openCreateAgentForm, setOpenCreateAgentForm] = useState(false);
  const api = apis[controller[type]["apiHook"]];
  const {
    setAgentDetails,
    suggestionsList,
    setSuggestionsList,
    onCreate,
    isSubmittingSection,
  } = api;
  const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 900,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
  }));

  const DRIVE_URL =
    "https://drive.google.com/drive/folders/1v5EXD-Nx42ntTySNddKBNNbCR4SpyhRX";

  return (
    <Page title="searchAgent">
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 20,
        }}
      >
        <Grid container>
          <Grid item xs={12} position={"relative"}>
            {!agentDetails && !openCreateAgentForm && (
              <Grid
                item
                justifyContent={"space-around"}
                alignItems={"center"}
                display={"flex"}
                mb={2}
              >
                <Box justifyContent={"center"} alignItems={"center"}>
                  <Button
                    onClick={() => window.open(DRIVE_URL, "_blank")}
                    variant="outlined"
                    sx={{
                      color: "black",
                      borderColor: "black",
                      "&:hover": {
                        bgcolor: "#4A148C",
                        color: "white",
                        borderColor: "black",
                      },
                    }}
                    size={"large"}
                    startIcon={<Iconify icon="bx:support" />}
                  >
                    Support Files
                  </Button>
                </Box>
                <Box justifyContent={"center"} alignItems={"center"}>
                  <Button
                    onClick={() => setOpenCreateAgentForm(true)}
                    variant="outlined"
                    sx={{
                      color: "black",
                      borderColor: "black",
                      "&:hover": {
                        bgcolor: "#4A148C",
                        color: "white",
                        borderColor: "black",
                      },
                    }}
                    size={"large"}
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    Add New Agent
                  </Button>
                </Box>
              </Grid>
            )}
            {openCreateAgentForm ? (
              <Card>
                <AgentForm
                  mode={"CREATE"}
                  isSubmitting={isSubmittingSection}
                  openForm={openCreateAgentForm}
                  setOpenForm={setOpenCreateAgentForm}
                  onCreate={onCreate}
                  type={type}
                />
              </Card>
            ) : (
              !agentDetails &&
              !openCreateAgentForm && (
                <RHFSearchBox
                  name="agentNum"
                  label="Enter Agent Number"
                  suggestions={suggestionsList}
                  setSuggestions={setSuggestionsList}
                  agentDetails={agentDetails}
                />
              )
            )}
            {!openCreateAgentForm && agentDetails && (
              <Card>
                <Grid container>
                  <Grid item container xs={12}>
                    <AgentDetailsCard route={"user"} data={agentDetails} />
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

const AgentForm = (props) => {
  const { openForm, setOpenForm, isSubmitting, mode, onCreate, type } = props;
  const formFieldsArray = controller[type]["formFields"];
  const formSchema = controller[type]["formSchema"];
  const formTitle = controller[type]["formTitle"];
  const formLayout = controller[type]["formLayout"];
  const formDefaultValues = controller[type]["formDefaultValues"];

  const onSubmit = async (vals) => {
    let params = {};
    formFieldsArray.map((f) => {
      params[f.formProps.name] = getValueFromObject(vals, f.formProps.name);
      params.role = "agent";
    });
    if (mode == "CREATE") {
      onCreate(params);
    }

    setOpenForm(false);
  };

  let defaultValues = useMemo(() => {
    let params = {};

    formFieldsArray.map((f) => {
      params[f.formProps.name] = getValueFromObject(
        formDefaultValues,
        f.formProps.name,
      );
    });
    return params;
  }, [openForm]);

  const methods = useForm({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  return (
    <CommonForm
      formTitle={formTitle.create}
      onClose={() => setOpenForm(false)}
      methods={methods}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
    >
      {formLayout === false ? (
        <Grid container spacing={3}>
          {formFieldsArray.map((field, index) => (
            <Grid item xs={6} key={field.name || index}>
              <field.component {...field.formProps} />
            </Grid>
          ))}
        </Grid>
      ) : (
        formLayout
      )}
    </CommonForm>
  );
};
