import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import actions from "src/redux/actions/actions";
import GoToDashboard from "./GoToDashboard";
import _ from "lodash";

const AgentDetailsCard = ({ data, route }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleRoutes(link, resetValue) {
    if (route === "admin") {
      navigate(`/admin/${link}`, { replace: true });
    } else {
      navigate(`/${link}`, { replace: true });
    }
    resetValue && dispatch(actions.setAgentDetails(false));
  }

  async function handleDownloadUser() {
    window.open(
      "https://agent-relation-backend-a46b57b96b9f.herokuapp.com/v1/users/download-agent-csv-data/" +
        data.id,
      "_self",
    );
  }
  return (
    <Box p={2} zIndex={999}>
      <GoToDashboard
        sx={{ top: 5, right: 3, px: 2, py: 1, userSelect: "none" }}
      />
      <Grid container p={1}>
        <Typography
          variant="h3"
          sx={{ textAlign: "center", width: "100%", mb: 3 }}
        >
          Agent Details
        </Typography>
        <Button
          variant="text"
          sx={{
            fontSize: "20px",
            position: "absolute",
            right: 0,
            px: 2,
            mx: 2,
          }}
          onClick={() => {
            dispatch(actions.setAgentDetails(false));
          }}
        >
          X
        </Button>
        {Object.entries(data).map(([key, value]) => {
          if (_.isObject(value)) {
            value = value.name;
          }
          return (
            <Grid
              item
              xs={12}
              container
              sm={12}
              key={key}
              p={1}
              justifyContent={"center"}
              alignItems={"center"}
              borderTop={".5px solid black"}
            >
              <Grid item xs={5}>
                <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                  {key}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="" sx={{ textTransform: "capitalize" }}>
                  {value}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
        <Grid
          item
          container
          xs={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
        >
          <Grid item>
            <Button
              variant="text"
              sx={{ fontSize: "20px" }}
              onClick={() => {
                handleRoutes("help");
              }}
            >
              Help Tickets
            </Button>
            <Button
              variant="text"
              sx={{ fontSize: "20px" }}
              onClick={() => {
                handleRoutes("data");
              }}
            >
              Data Report
            </Button>
            <Button
              variant="text"
              sx={{ fontSize: "20px" }}
              onClick={() => {
                handleRoutes("receipt");
              }}
            >
              Receipts
            </Button>
            <Button
              variant="text"
              sx={{ fontSize: "20px" }}
              onClick={() => {
                handleRoutes("audit");
              }}
            >
              Audit Report
            </Button>
            <Button
              variant="text"
              sx={{ fontSize: "20px" }}
              onClick={() => {
                handleRoutes("contacts");
              }}
            >
              Agent Contacts
            </Button>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Button
                variant="text"
                sx={{ fontSize: "20px" }}
                onClick={() => {
                  handleDownloadUser();
                }}
              >
                Download User CSV
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgentDetailsCard;
