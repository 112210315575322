import { Grid, TableCell } from "@mui/material";
import _ from "lodash";
import { RHFSelect, RHFTextField } from "src/components/hook-form";
import { getFormattedDate, isIsoDate } from "src/utils/commons";
import * as Yup from "yup";

const sectionTitle = "Agent Contacts";
//API
const apiHook = "agentContactsApi";
const endPoints = {
  get: "contact",
  create: "contact",
  remove: "contact/",
  update: "contact/",
};
//TABLE

const enablePagination = true;
const tableHead = [
  { id: "name", label: "Contact Name", alignRight: false },
  { id: "numbers", label: "Contact Number", alignRight: false },
  { id: "email", label: "Contact Email", alignRight: false },
  { id: "comment", label: "Comments", alignRight: false },
  { id: "agentId", label: "Agent Number", alignRight: false },
  { id: null, label: "", alignRight: true },
];
const tableActions = { edit: false, delete: false, create: true };
const tableRowRender = (rowData) =>
  tableHead.map((head) => {
    if (!_.has(rowData, head.id)) return;
    if (isIsoDate(rowData[head.id])) {
      return (
        <TableCell key={head.id} align="left">
          {getFormattedDate(rowData[head.id])}
        </TableCell>
      );
    }
    if (head.id === "agentId" && _.isObject(rowData[head.id])) {
      return (
        <TableCell key={head.id} align="left">
          {rowData[head.id].agentNumber}
        </TableCell>
      );
    }
    return (
      <TableCell key={head.id} align="left">
        {rowData[head.id]}
      </TableCell>
    );
  });

//FORMS
const formFields = [
  {
    component: RHFTextField,
    formProps: {
      name: "agentId",
      label: "Agent Number",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "name",
      label: "Contact Name",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "numbers",
      label: "Contact Number",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "email",
      label: "Contact Email",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "comment",
      label: "Comment",
      multiline: true,
      maxRows: 5,
    },
  },
];

const formLayout = (
  <Grid container spacing={3}>
    <Grid item xs={6}>
      <RHFTextField name="name" label={"Contact Name"} />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField name="numbers" label={"Contact Number"} />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField name="email" label={"Contact Email"} />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField name="comment" label={"Comment"} multiline maxRows={5} />
    </Grid>
  </Grid>
); //Auto render layout if false or pass react element with form render

const formSchema = Yup.object().shape({
  agentId: Yup.string(),
  name: Yup.string().required("Contact Name is required"),
  numbers: Yup.string().required("Contact Number is required"),
  email: Yup.string().email().required("Contact Email is required"),
  comment: Yup.string().required("Comment is required"),
});
const formDefaultValues = {};

const formTitle = {
  create: "Create New Agent Contact",
  edit: "Edit New Agent Contact",
};

//Read only form fields when not edit/delete available
const readOnlyFieldsMap = {};

export default {
  apiHook,
  formFields,
  formLayout,
  tableHead,
  tableActions,
  formSchema,
  formTitle,
  formDefaultValues,
  sectionTitle,
  endPoints,
  enablePagination,
  tableRowRender,
  readOnlyFieldsMap,
};
