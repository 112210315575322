// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  TableRow,
  TableCell,
  Grid,
  Drawer,
  Box,
  IconButton,
} from "@mui/material";
// components
import Page from "../../components/Page";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useAuth } from "src/utils/AuthContext";
import _ from "lodash";
import { useMemo, useState } from "react";
import Iconify from "src/components/Iconify";
import CommonTable from "src/components/CommonTable";
import MoreMenu from "src/components/CommonTable/MoreMenu";
import { controller, types } from "src/controllers";
import CommonForm from "src/layouts/dashboard/CommonForm";
import dayjs from "dayjs";
import { getDDMMYYY, getValueFromObject, isIsoDate } from "src/utils/commons";
import Scrollbar from "src/components/Scrollbar";
import ExportExcel from "src/components/ExportExcel";
import { useSelector } from "react-redux";
import RHFSearchBox from "src/components/hook-form/RHFSearchbox";
import GoToDashboard from "src/components/GoToDashboard";
import GoToAgentDetails from "src/components/GoToAgemtDetails";
import usePermissions from "src/hooks/usePermissions";
import AgentDetailsCard from "src/components/AgentDetailsCard";
import actions from "src/redux/actions/actions";
import { useDispatch } from "react-redux";

export default function TableView({ type }) {
  const [openForm, setOpenForm] = useState(false);
  const isEditMode = typeof openForm == "object";
  const { apis } = useAuth();
  const permissions = usePermissions();
  const api = apis[controller[type]["apiHook"]];
  const agentDetailsApi = apis[controller[types.AGENTS]["apiHook"]];
  const typesApi = apis[controller[types.TYPES]["apiHook"]];
  const tableHead = controller[type]["tableHead"];
  const tableActions = controller[type]["tableActions"];
  const sectionTitle = controller[type]["sectionTitle"];
  const disablePagination = !controller[type]["enablePagination"];
  const isReadOnly =
    (!tableActions.delete && !tableActions.create && !tableActions.edit) ||
    tableActions.view;

  const { onCreate, isSubmitting, isLoading, data, onDelete, onUpdate } = api;
  const { suggestionsList, setSuggestionsList } = agentDetailsApi;
  const { typesList, setTypesList } = typesApi;
  const agentDetails = useSelector((state) => state.reducer.agentDetails);
  const userData = useSelector((state) => state.reducer.userData);
  const onEdit = (item) => {
    setOpenForm(item);
  };

  const hasCreatePermission = permissions.create ?? false;
  const hasSearchBox = permissions.search ?? false;
  const isAdmin = userData.role === "admin" ?? false;

  return (
    <Page title={sectionTitle}>
      <Container>
        {agentDetails && hasSearchBox && isAdmin && (
          <Box sx={{ width: "100%", position: "relative" }}>
            <Card sx={{ position: "absolute", zIndex: 995, top: 0 }}>
              <Grid container>
                <Grid item container xs={12}>
                  <AgentDetailsCard
                    data={agentDetails}
                    route={isAdmin ? "admin" : "user"}
                  />
                </Grid>
              </Grid>
            </Card>
          </Box>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          position={"relative"}
        >
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="space-between"
          >
            <Typography variant="h4" gutterBottom>
              {sectionTitle}
            </Typography>
            {agentDetails && (
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ color: "purple" }}
              >
                Agent Name: {agentDetails.name}
              </Typography>
            )}
          </Stack>

          <GoToDashboard
            visible={agentDetails ? true : false}
            textColor={"white"}
            sx={{
              bottom: -40,
              left: -20,
              px: 2,
              py: 1,
              userSelect: "none",
            }}
          />

          <GoToAgentDetails
            route={isAdmin ? "/admin/agents" : "/search"}
            visible={agentDetails ? true : false}
            textColor={"white"}
            sx={{ bottom: -40, right: 0, px: 2, py: 1, userSelect: "none" }}
          />
          {hasSearchBox && (
            <Box
              position={"absolute"}
              sx={{
                top: -20,
                right: 0,
                left: 0,
                mx: "auto",
                zIndex: 800,
                maxWidth: "300px",
              }}
            >
              <RHFSearchBox
                type="table"
                name="agentNum"
                label="Enter Agent Number"
                suggestions={suggestionsList}
                setSuggestions={setSuggestionsList}
                agentDetails={agentDetails}
              />
            </Box>
          )}

          <Box sx={{ mb: userData.role === "admin" ? 2 : 0 }}>
            {!_.isEmpty(data) && <ExportExcel type={type} />}
            {hasCreatePermission && !openForm && (
              <Button
                onClick={() => setOpenForm(true)}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add
              </Button>
            )}
          </Box>
        </Stack>

        {openForm && isReadOnly && (
          <ReadOnlyView
            openForm={openForm}
            type={type}
            setOpenForm={setOpenForm}
          />
        )}

        {openForm && !isReadOnly ? (
          <Card>
            <Form
              mode={isEditMode ? "UPDATE" : "CREATE"}
              isSubmitting={isSubmitting}
              setTypes={setTypesList ? setTypesList : ""}
              typesList={typesList ? typesList : ""}
              agentNumber={agentDetails ? agentDetails.agentNumber : ""}
              openForm={openForm}
              onUpdate={onUpdate}
              setOpenForm={setOpenForm}
              onCreate={onCreate}
              type={type}
            />
          </Card>
        ) : (
          <Card>
            <CommonTable
              api={api}
              loading={isLoading}
              tableTitle={type}
              tableHead={tableHead}
              list={data || []}
              disablePagination={disablePagination}
              RowItem={(params) => {
                return (
                  <RowItem
                    {...params}
                    onEdit={onEdit}
                    type={type}
                    onDelete={onDelete}
                    isReadOnly={isReadOnly}
                  />
                );
              }}
            />
          </Card>
        )}
      </Container>
    </Page>
  );
}

const RowItem = ({ row, onEdit, onDelete, type, isReadOnly }) => {
  const { id } = row;
  const tableActions = controller[type]["tableActions"];
  const sectionTitle = controller[type]["sectionTitle"];
  const tableRowRender = controller[type]["tableRowRender"];
  const permissions = usePermissions();
  const dispatch = useDispatch();
  const menuProps = {};
  if (permissions) {
    if (permissions.edit) {
      menuProps.onEdit = () => onEdit(row);
    }
    if (permissions.delete) {
      menuProps.onDelete = () => onDelete(id);
    }
  } else {
    if (tableActions.edit) {
      menuProps.onEdit = () => onEdit(row);
    }
    if (tableActions.delete) {
      menuProps.onDelete = () => onDelete(id);
    }
    if (isReadOnly) {
      menuProps.onView = () => onEdit(row);
    }
  }
  const handleRowClick = (selectedAgent, e) => {
    if (
      e.target.classList.contains("MuiIconButton-root") ||
      e.target.classList.contains("iconify") ||
      e.target.classList.value === ""
    ) {
      e.stopPropagation();
    } else {
      if (sectionTitle === "Agents") {
        dispatch(actions.setAgentDetails(selectedAgent));
      }
    }
  };

  return (
    <TableRow hover key={id} onClick={(e) => handleRowClick(row, e)}>
      {tableRowRender(row)}
      <TableCell align="right">
        <MoreMenu {...menuProps} />
      </TableCell>
    </TableRow>
  );
};

const Form = (props) => {
  const {
    openForm,
    setOpenForm,
    onUpdate,
    isSubmitting,
    mode,
    onCreate,
    type,
    agentNumber,
  } = props;
  const formFieldsArray = controller[type]["formFields"];
  const formSchema = controller[type]["formSchema"];
  const formTitle = controller[type]["formTitle"];
  const formLayout = controller[type]["formLayout"];
  const formDefaultValues = controller[type]["formDefaultValues"];
  const userData = useSelector((state) => state.reducer.userData);

  const onSubmit = async (vals) => {
    let params = {};
    formFieldsArray.map((f) => {
      // if (vals[f.formProps.name] instanceof dayjs) {
      //   params[f.formProps.name] = vals[f.formProps.name].toDate();
      //   return;
      // }
      params[f.formProps.name] = getValueFromObject(vals, f.formProps.name);
    });
    if (mode == "CREATE") {
      onCreate(params);
    }
    if (mode == "UPDATE") {
      onUpdate({
        ...params,
        id: openForm.id,
      });
    }
    setOpenForm(false);
  };

  let defaultValues = useMemo(() => {
    let params = {};
    if (mode === "UPDATE") {
      formFieldsArray.map((f) => {
        if (_.isObject(openForm[f.formProps.name])) {
          params[f.formProps.name] = getValueFromObject(
            openForm[f.formProps.name],
            "id",
          );
          return;
        }

        if (f.formProps.name === "password") {
          params[f.formProps.name] = getValueFromObject(
            openForm,
            "realPassword",
          );
          return;
        }
        if (isIsoDate(openForm[f.formProps.name])) {
          //Parse date
          params[f.formProps.name] = dayjs(
            getValueFromObject(openForm, f.formProps.name),
          );
          return;
        }
        params[f.formProps.name] = getValueFromObject(
          openForm,
          f.formProps.name,
        );
      });
      return params;
    }
    formFieldsArray.map((f) => {
      params[f.formProps.name] = getValueFromObject(
        formDefaultValues,
        f.formProps.name,
      );
    });
    return params;
  }, [openForm]);

  const methods = useForm({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  return (
    <CommonForm
      formTitle={mode === "UPDATE" ? formTitle.edit : formTitle.create}
      onClose={() => setOpenForm(false)}
      methods={methods}
      onSubmit={onSubmit}
      agentNumber={agentNumber}
      isSubmitting={isSubmitting}
    >
      {formLayout === false ? (
        <Grid container spacing={3}>
          {formFieldsArray.map((field, index) => (
            <Grid item xs={6} key={field.name || index}>
              <field.component {...field.formProps} />
            </Grid>
          ))}
        </Grid>
      ) : (
        formLayout
      )}
    </CommonForm>
  );
};

const ReadOnlyView = (props) => {
  const { openForm, setOpenForm, type } = props;
  const formTitle = controller[type]["formTitle"];
  const readOnlyFieldsMap = controller[type]["readOnlyFieldsMap"];

  return (
    <Drawer
      anchor="right"
      open={openForm}
      onCloseSidebar={() => setOpenForm(false)}
      PaperProps={{
        sx: { width: 500 },
      }}
    >
      <Scrollbar
        sx={{
          height: 1,
          "& .simplebar-content": {
            height: 1,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box sx={{ mb: 5, mx: 4 }}>
          <Stack direction="row" sx={{ mt: 4, mb: 5 }} alignItems="center">
            <Typography variant="h4" flex={1}>
              {formTitle.view}
            </Typography>
            <IconButton onClick={() => setOpenForm(false)}>
              <Iconify icon="codicon:chrome-close" width={30} height={30} />
            </IconButton>
          </Stack>
          <Stack spacing={2} pb={20}>
            {Object.keys(readOnlyFieldsMap).map((fieldKey) => {
              if (!openForm[fieldKey]) {
                return <></>;
              }
              return (
                <Stack key={fieldKey}>
                  <Typography variant="caption">
                    {readOnlyFieldsMap[fieldKey]}
                  </Typography>
                  <Typography>
                    {isIsoDate(openForm[fieldKey])
                      ? getDDMMYYY(openForm[fieldKey])
                      : openForm[fieldKey]}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Box>
      </Scrollbar>
    </Drawer>
  );
};
