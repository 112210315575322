import { Grid, TableCell } from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import SearchAgentNumber from "src/components/SearchAgentNumber";
import { RHFTextField } from "src/components/hook-form";
import { getFormattedDate, isIsoDate } from "src/utils/commons";
import * as Yup from "yup";

const sectionTitle = "Agent Location";
//API
const apiHook = "locationApi";
const endPoints = {
  get: "location",
  create: "location",
  update: "location/",
  remove: "location/",
};
//TABLE

const enablePagination = true;
const tableHead = [
  { id: "province", label: "Province", alignRight: false },
  { id: "suburb", label: "Suburb", alignRight: false },
  { id: "googleLink", label: "Google Link", alignRight: false },
  { id: "agentId", label: "Agent Number", alignRight: false },
  { id: null, label: "", alignRight: true },
];

const tableActions = { edit: false, delete: true, create: true };
const tableRowRender = (rowData) =>
  tableHead.map((head) => {
    if (!_.has(rowData, head.id)) return;
    if (head.id === "agentId" && _.isObject(rowData[head.id])) {
      return (
        <TableCell key={head.id} align="left">
          {rowData[head.id].agentNumber}
        </TableCell>
      );
    }
    if (isIsoDate(rowData[head.id])) {
      return (
        <TableCell key={head.id} align="left">
          {getFormattedDate(rowData[head.id])}
        </TableCell>
      );
    }

    return (
      <TableCell key={head.id} align="left">
        {rowData[head.id]}
      </TableCell>
    );
  });

//FORMS
const formFields = [
  {
    component: RHFTextField,
    formProps: {
      name: "agentId",
      label: "Agent Id",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "province",
      label: "Province",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "suburb",
      label: "Suburb",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "googleLink",
      label: "Google Link",
    },
  },
];

const formLayout = (
  <Grid container spacing={3}>
    <Grid item xs={6}>
      <RHFTextField name="province" label={"Province"} />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField name="suburb" label={"Suburb"} />
    </Grid>
    <Grid item xs={6}>
      <RHFTextField name="googleLink" label={"Google Link"} />
    </Grid>
    <Grid item xs={6}>
      <SearchAgentNumber name="searchAgent" label={"Agent Number"} />
    </Grid>
  </Grid>
); //Auto render layout if false or pass react element with form render

const formSchema = Yup.object().shape({
  province: Yup.string().required("Province is required"),
  suburb: Yup.string().required("Suburb is required"),
  googleLink: Yup.string().required("Google Link is required"),
  searchAgent: Yup.string(),
});
const formDefaultValues = {
  province: "",
  suburb: "",
  googleLink: "",
  searchAgent: "",
};

const formTitle = { create: "Create New Location", edit: "Edit Location" };

//Read only form fields when not edit/delete available
const readOnlyFieldsMap = {};

export default {
  apiHook,
  formFields,
  formLayout,
  tableHead,
  tableActions,
  formSchema,
  formTitle,
  formDefaultValues,
  sectionTitle,
  endPoints,
  enablePagination,
  tableRowRender,
  readOnlyFieldsMap,
};
