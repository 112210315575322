import { Grid, TableCell } from "@mui/material";
import _ from "lodash";
import { RHFSelect, RHFTextField } from "src/components/hook-form";
import { getFormattedDate, isIsoDate } from "src/utils/commons";
import * as Yup from "yup";

const sectionTitle = "Users";
//API
const apiHook = "usersApi";
const endPoints = {
  get: "users",
  create: "users",
  update: "users/",
  remove: "users/",
};
//TABLE
const enablePagination = true;
const tableHead = [
  // { id: "id", label: "ID", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "number", label: "Number", alignRight: false },
  { id: "role", label: "Role", alignRight: false },
  { id: "realPassword", label: "Password", alignRight: false },
  { id: null, label: "", alignRight: true },
];
const tableActions = { edit: false, delete: true, create: true };
const tableRowRender = (rowData) =>
  tableHead.map((head) => {
    if (!_.has(rowData, head.id)) return;
    if (isIsoDate(rowData[head.id])) {
      return (
        <TableCell key={head.id} align="left">
          {getFormattedDate(rowData[head.id])}
        </TableCell>
      );
    }
    return (
      <TableCell key={head.id} align="left">
        {rowData[head.id]}
      </TableCell>
    );
  });

//FORMS
const formFields = [
  {
    component: RHFTextField,
    formProps: {
      name: "name",
      label: "Name",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "email",
      label: "Email",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "number",
      label: "Number",
    },
  },
  {
    component: RHFTextField,
    formProps: {
      name: "password",
      label: "Password",
      type: "password",
    },
  },
  {
    component: RHFSelect,
    formProps: {
      name: "role",
      label: "Role",
      options: ["user", "admin"],
    },
  },
];

const formLayout = false; //Auto render layout if false or pass react element with form render

const formSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  name: Yup.string().required("Name is required"),
  number: Yup.string().required("Number is required"),
  role: Yup.string().required("Role is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).*$/,
      "Password must contain at least 1 letter and 1 number",
    )
    .required("Password is required"),
});
const formDefaultValues = {};

const formTitle = { create: "Create User", edit: "Edit User" };

//Read only form fields when not edit/delete available
const readOnlyFieldsMap = {};

export default {
  apiHook,
  formFields,
  formLayout,
  tableHead,
  tableActions,
  formSchema,
  formTitle,
  formDefaultValues,
  sectionTitle,
  endPoints,
  enablePagination,
  tableRowRender,
  readOnlyFieldsMap,
};
