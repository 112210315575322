import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
// layouts
// Pages
import NotFound from "./pages/Page404";
// import User from "./pages/agent/User";

// admin routes ----------------------------------------------------------------------
import Login from "./pages/Login";
import DashboardLayout from "./layouts/dashboard";
import TableView from "./layouts/dashboard/TableView";
import { types } from "./controllers";
import SearchAgents from "./pages/SearchAgents";
import DashboardApp from "./pages/DashboardApp";
const publicRoutes = [
  {
    path: "/",
    children: [
      {
        path: "/",
        element: <Navigate to="/login" />,
      },
      { path: "login", element: <Login userType="user" /> },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];
const publicAdminRoutes = [
  {
    path: "/",
    children: [
      {
        path: "/",
        element: <Navigate to="/admin" />,
      },
      { path: "/admin", element: <Navigate to="/admin/login" /> },
      { path: "/admin/login", element: <Login userType="admin" /> },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

const protectedRoutes = [
  {
    path: "/",
    element: <DashboardLayout type="user" />,
    children: [
      { path: "/", element: <Navigate to="/search" /> },
      { path: "/search", element: <SearchAgents type={types.AGENTS} /> },
      { path: "/help", element: <TableView type={types.HELPTICKET} /> },
      {
        path: "/contacts",
        element: <TableView type={types.AGENTCONTACTS} />,
      },
      {
        path: "/receipt",
        element: <TableView type={types.RECEIPTS} />,
      },
      {
        path: "/audit",
        element: <TableView type={types.AUDIT} />,
      },
      {
        path: "/data",
        element: <TableView type={types.DATAREPORTS} />,
      },
    ],
  },

  { path: "404", element: <NotFound /> },
  { path: "*", element: <Navigate to="/404" /> },
];

const adminProtectedRoutes = [
  {
    path: "/admin",
    element: <DashboardLayout type="admin" />,
    children: [
      {
        path: "dashboard",
        element: <DashboardApp />,
      },
      {
        path: "agents",
        element: <TableView type={types.AGENTS} />,
      },
      {
        path: "users",
        element: <TableView type={types.USERS} />,
      },
      {
        path: "help",
        element: <TableView type={types.HELPTICKET} />,
      },
      {
        path: "receipt",
        element: <TableView type={types.RECEIPTS} />,
      },
      {
        path: "contacts",
        element: <TableView type={types.AGENTCONTACTS} />,
      },
      {
        path: "audit",
        element: <TableView type={types.AUDIT} />,
      },
      {
        path: "location",
        element: <TableView type={types.LOCATION} />,
      },
      {
        path: "data",
        element: <TableView type={types.DATAREPORTS} />,
      },
      {
        path: "type",
        element: <TableView type={types.TYPES} />,
      },
    ],
  },
  { path: "/", element: <Navigate to="/admin/dashboard" /> },
  { path: "404", element: <NotFound /> },
  { path: "*", element: <Navigate to="/404" /> },
];

export default function Router() {
  const hostname = window.location.hostname;

  const { authToken, userData } = useSelector((state) => state.reducer);
  const adminRoutess = useRoutes(adminProtectedRoutes);
  const publicRoutess = useRoutes(publicRoutes);
  const publicAdminRoutess = useRoutes(publicAdminRoutes);
  const protectedRoutess = useRoutes(protectedRoutes);

  if (userData && authToken) {
    if (userData.role == "admin") return adminRoutess;
    return protectedRoutess;
  }
  if (hostname === "adminagentrelation.jivemarket.com") {
    return publicAdminRoutess;
  }
  // if (hostname === "localhost") {
  //   return publicAdminRoutess;
  // }
  return publicRoutess;
}
