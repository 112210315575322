import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Iconify from "../Iconify";
import { useState } from "react";

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({ name, InputProps = {}, ...other }) {
  const { control } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const isPassType = other?.type === "password";
  const isTelType = other?.type === "tel";
  const passwordInputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
          <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
        </IconButton>
      </InputAdornment>
    ),
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={
            typeof field.value === "number" && field.value === 0
              ? ""
              : field.value
          }
          error={!!error}
          helperText={error?.message}
          {...other}
          type={isPassType && showPassword ? "text" : other.type}
          InputProps={isPassType ? passwordInputProps : InputProps}
        />
      )}
    />
  );
}
