import { TableCell } from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import { RHFTextField } from "src/components/hook-form";
import { getFormattedDate, isIsoDate } from "src/utils/commons";
import * as Yup from "yup";

const sectionTitle = "Agent Types";
//API
const apiHook = "typesApi";
const endPoints = {
  get: "type",
  create: "type",
  update: "type/",
  remove: "type/",
};
//TABLE

const enablePagination = true;
const tableHead = [
  { id: "name", label: "Type", alignRight: false },
  { id: "comment", label: "Comments", alignRight: false },
  { id: null, label: "", alignRight: false },
];

const tableActions = { edit: false, delete: true, create: true };
const tableRowRender = (rowData) =>
  tableHead.map((head) => {
    if (!_.has(rowData, head.id)) return;

    if (isIsoDate(rowData[head.id])) {
      return (
        <TableCell key={head.id} align="left">
          {getFormattedDate(rowData[head.id])}
        </TableCell>
      );
    }
    return (
      <TableCell key={head.id} align="left">
        {rowData[head.id]}
      </TableCell>
    );
  });

//FORMS
const formFields = [
  {
    component: RHFTextField,
    formProps: {
      name: "name",
      label: "Type",
    },
  },

  {
    component: RHFTextField,
    formProps: {
      name: "comment",
      label: "Comment",
      multiline: true,
      maxRows: 5,
    },
  },
];

const formLayout = false; //Auto render layout if false or pass react element with form render

const formSchema = Yup.object().shape({
  name: Yup.string().required("Agent Type is required"),
  comment: Yup.string().required("Comment is required"),
});
const formDefaultValues = {
  dateDelivered: dayjs(),
  name: "",
  comment: "",
};

const formTitle = { create: "Create New Type", edit: "Edit Type" };

//Read only form fields when not edit/delete available
const readOnlyFieldsMap = {};

export default {
  apiHook,
  formFields,
  formLayout,
  tableHead,
  tableActions,
  formSchema,
  formTitle,
  formDefaultValues,
  sectionTitle,
  endPoints,
  enablePagination,
  tableRowRender,
  readOnlyFieldsMap,
};
