import { memo, useEffect, useMemo, useState } from "react";
import { apiErrorHandler, appsApi } from "./api";
import _ from "lodash";
import { useAlert } from "src/hooks/useNotify";
import { getFormData, includesFileType } from "src/utils/commons";
import { controller } from "src/controllers";
import { useDispatch } from "react-redux";
import actions from "src/redux/actions/actions";
import { useSelector } from "react-redux";

export default function useLocationApi({ authToken, userData, type }) {
  const [data, setData] = useState([]);
  const [response, setResponse] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [typesList, setTypesList] = useState([]);

  const notify = useAlert();
  const dispatch = useDispatch();

  const agentDetails = useSelector((state) => state.reducer.agentDetails);
  const memoizedagentDetails = useMemo(() => {
    return agentDetails;
  }, [agentDetails]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const DEFAULT_PAGE_LIMIT = 50;

  const paginationState = useMemo(() => {
    const isPaginated =
      controller[type].enablePagination &&
      response &&
      _.has(response, "page") &&
      _.has(response, "totalPages") &&
      _.has(response, "limit") &&
      _.has(response, "totalResults");

    if (!isPaginated) return false;

    return {
      limit: response.limit,
      totalPages: response.totalPages,
      page: response.page,
      totalResults: response.totalResults,
    };
  }, [response]);

  const {
    get = "",
    create = "",
    remove = "",
    update = "",
  } = controller[type].endPoints;

  useEffect(() => {
    if (authToken && userData.role === "admin") {
      fetchData();
    }
  }, [authToken]);

  const apiCall = async (methodType, url, params = {}) => {
    let body = { ...params };
    let config = {};
    let isFormData = includesFileType(params);

    if (isFormData) {
      body = getFormData(body);
      config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
    }
    try {
      setIsSubmitting(true);
      const res = await appsApi[methodType](url, body, config);
      if (res && (res.results || res.id || res.message)) {
        return res;
      }
      throw res;
    } catch (error) {
      notify.toastError("Oops! Something went wrong!");
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchData = async () => {
    const query = controller[type].enablePagination
      ? `&limit=${DEFAULT_PAGE_LIMIT}`
      : "";
    try {
      setIsLoading(true);
      const data = await apiCall("get", get + `?limit=${DEFAULT_PAGE_LIMIT}`);
      setData(data.results);
      setResponse(data);
      setIsLoading(false);
    } catch (error) {
      notify.toastError("Oops! Something went wrong!");
      console.error(error);
    }
  };

  const onCreate = async (params) => {
    console.log(params);
    params.agentId = memoizedagentDetails.id;
    const res = await apiCall("post", create, params);
    console.log(res);
    if (!res) return;
    notify.toastSuccess(`Location: ${params.province} Created successfully!`);
    dispatch(actions.setAgentDetails(false));
    fetchData();
  };

  const onUpdate = async (params) => {
    const { id, agentId, ...rest } = params;
    params.agentId = memoizedagentDetails.id;
    delete params["id"];

    const res = await apiCall("put", update + id, params);
    if (!res) return;
    notify.toastSuccess("Updated successfully!");
    dispatch(actions.setAgentDetails(false));
    fetchData();
  };

  const onDelete = async (id) => {
    const res = await apiCall("delete", remove + id);
    if (res.message) {
      notify.toastSuccess(res.message);
    }
    if (!res) return;
    setData((old) => old.filter((o) => o.id !== id));
  };

  const paginatedFetch = async (query = "", limit = 50, page = 1) => {
    if (!paginationState) return;
    try {
      setIsLoading(true);
      const res = await appsApi.get(
        get.replaceAll("?", "") +
          `?${query.replaceAll("?", "")}&limit=${limit}&page=${page}`,
      );
      setData(res.results);
      setResponse(res);
      setIsLoading(false);
    } catch (error) {
      notify.toastError("Oops! Something went wrong!");
      console.error(error);
    }
  };

  return {
    data,
    isLoading,
    typesList,
    setTypesList,
    onCreate,
    onUpdate,
    isSubmitting,
    fetchData,
    onDelete,
    paginationState,
    paginatedFetch,
    apiCall,
  };
}
